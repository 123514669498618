import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit"
import moment from "moment"
import "../../../assets/scss/datatables.scss"
import { useDispatch, useSelector } from "react-redux"
import { numberWithCommas } from "common/strings"
import {
  getSoftskillsPositionName,
  getSoftskillsProfileName,
  getSoftskillsRegionName,
} from "pages/SoftSkills/Helpers/Helpers"

const SoftskillsAbilitiesUsersListTable = props => {
  const { SearchBar } = Search

  const [dropDown, setDropDown] = useState(0)
  const [users, setUsers] = useState([])
  const [user, setUser] = useState({
    userId: 0,
    email: "",
  })
  const [page, setPage] = useState(undefined)
  const [filteredUsers, setFilteredUsers] = useState([])
  const [sizePerPage, setSizePerPage] = useState(undefined)

  const { whoIAm, userAbilities, loading } = useSelector(state => ({
    whoIAm: state.ProfileNew.user,
    userAbilities: state.SoftskillsAbilitiesAllUsers.users,
    loading: state.SoftskillsAbilitiesAllUsers.loading,
  }))

  const userId = whoIAm?.userId ?? 0
  const profileId = whoIAm?.softskillsProfileId ?? 0
  const regionId = whoIAm?.softskillsRegionId ?? 0

  useEffect(() => {
    if (userAbilities.length > 0) {
      let usersPrev = []
      for (let i = 0; i < userAbilities.length; i++) {
        let user = userAbilities[i]
        const userRegionId =
          user.softskillsRegionId ?? user.id_region_softskills
        if (profileId === 3 && user.softskillsProfileId !== 4) {
          continue
        }

        if (profileId === 3 && regionId !== userRegionId) {
          continue
        }

        if (profileId !== 1 && profileId !== 2 && profileId !== 3) {
          continue
        }

        let courseDate = null
        let courses = 0
        let completedCourses = 0
        let currentAbility = "-"
        let completedAbilities = 0
        for (const ability of user.abilities) {
          if (ability.current) {
            currentAbility = ability.ability.abilityName
          }
          let finishedCourseOfAbility = 0
          for (const course of ability.courses) {
            courses++
            const courseUser = course.courseUsers.find((value, index) => {
              return value.userId === user.userId
            })
            if (courseUser) {
              if (courseDate === null) {
                courseDate = courseUser.createdAt
              } else if (moment(courseUser.createdAt).isAfter(courseDate)) {
                courseDate = courseUser.createdAt
              }
              if (courseUser.statusId === 3) {
                completedCourses++
                finishedCourseOfAbility++
              }
            }
          }
          if (finishedCourseOfAbility === ability.courses.length) {
            completedAbilities++
          }
        }

        const avgAbilities =
          user.abilities.length > 0
            ? (completedAbilities / user.abilities.length) * 100
            : 0
        const avgCourses = courses > 0 ? (completedCourses / courses) * 100 : 0

        const newUser = {
          userId: user.userId,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          softskillsRegionId: userRegionId,
          softskillsProfileId:
            user.softskillsProfileId ?? user.id_perfil_softskills,
          softskillsPositionId:
            user.softskillsPositionId ?? user.id_posicion_softskills,
          lastCourseDate: courseDate,
          lastActivity: user.lastActivity,
          currentAbility: currentAbility,
          completedCourses: `${completedCourses} / ${courses}`,
          avgAbilities: avgAbilities,
          completedAssignedAbilities: `${completedAbilities} / ${user.abilities.length}`,
          avgCourses: avgCourses,
        }
        usersPrev.push(newUser)
      }
      setUsers(usersPrev)
    }
  }, [userAbilities])

  const dropDownActions = (cell, row, rowIndex, extraData) => {
    const active = extraData?.active ?? 0
    return (
      <Dropdown
        key={`dropdown-${row.userId}`}
        isOpen={row.userId === active}
        toggle={() => {
          setDropDown(row.userId === active ? 0 : row.userId)
        }}
      >
        <DropdownToggle color="primary" className="btn btn-primary btn-md">
          Acciones <i className="mdi mdi-chevron-down" />
        </DropdownToggle>
        <DropdownMenu className="white-celf-background">
          <DropdownItem>
            <Link to={`/soft-skills/show-user/detail/${row.userId}`}>
              Ver detalles
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    )
  }

  const columns = [
    {
      dataField: "userId",
      text: "Id",
      sort: false,
      hidden: true,
    },
    {
      dataField: "firstName",
      text: "Nombre completo y correo",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <div key={`nombre-${row.userId}`}>
            <strong>
              <Link to={`/soft-skills/show-user/detail/${row.userId}`}>
                {row.firstName} {row.lastName}
              </Link>
            </strong>
            <br />
            <span>{row.email}</span>
          </div>
        )
      },
      csvFormatter: (column, row) => `${row.firstName} ${row.lastName}`,
    },
    {
      dataField: "lastName",
      text: "Apellido",
      sort: true,
      csvExport: false,
      hidden: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      csvExport: true,
      hidden: true,
    },
    {
      dataField: "softskillsProfileId",
      text: "Perfil",
      sort: true,
      csvExport: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <div key={`perfil-ss-${row.userId}`}>
            {getSoftskillsProfileName(row.softskillsProfileId)}
          </div>
        )
      },
      csvFormatter: (column, row) =>
        getSoftskillsProfileName(row.softskillsProfileId),
    },
    {
      dataField: "softskillsRegionId",
      text: "Región",
      sort: true,
      csvExport: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <div key={`region-ss-${row.userId}`}>
            {getSoftskillsRegionName(row.softskillsRegionId)}
          </div>
        )
      },
      csvFormatter: (column, row) =>
        getSoftskillsRegionName(row.softskillsRegionId),
    },
    {
      dataField: "softskillsPositionId",
      text: "Posición",
      sort: true,
      csvExport: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <div key={`posicion-ss-${row.userId}`}>
            {getSoftskillsPositionName(row.softskillsPositionId)}
          </div>
        )
      },
      csvFormatter: (column, row) =>
        getSoftskillsPositionName(row.softskillsPositionId),
    },
    {
      dataField: "lastActivity",
      text: "Último ingreso",
      csvText: "Último ingreso",
      sort: true,
      align: "center",
      CSVExport: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        return (
          <span key={`activity-${row.userId}`}>
            {row.lastActivity !== null
              ? moment(row.lastActivity).locale("es").format("DD/MM/YYYY")
              : "-"}
          </span>
        )
      },
      csvFormatter: (column, row) =>
        moment(row.lastActivity).format("D/M/YYYY"),
    },
    {
      dataField: "completedAssignedAbilities",
      text: "Progreso de habilidades",
      sort: true,
      align: "center",
      CSVExport: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <div>
            <strong>{Math.round(row.avgAbilities)}%</strong>
            <br />
            {row.completedAssignedAbilities}
          </div>
        )
      },
      csvFormatter: (column, row) => row.completedAssignedAbilities,
    },
    {
      dataField: "currentAbility",
      text: "Habilidad actual",
      sort: true,
      align: "center",
      CSVExport: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <h4 style={{ marginBottom: 0 }} key={`current-ability-${row.userId}`}>
            <span className={`pt-2 badge bg-primary`}>
              {row.currentAbility}
            </span>
          </h4>
        )
      },
      csvFormatter: (column, row) => row.currentAbility,
    },
    {
      dataField: "completedCourses",
      text: "Progreso de cursos",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <div>
            <strong>{Math.round(row.avgCourses)}%</strong>
            <br />
            {row.completedCourses}
          </div>
        )
      },
      csvFormatter: (column, row) => row.completedCourses,
    },
    {
      dataField: "lastCourseDate",
      text: "Último registro de curso",
      csvText: "Último registro de curso",
      sort: true,
      align: "center",
      CSVExport: true,
      // eslint-disable-next-line react/display-name
      formatter: (column, row) => {
        console.log(row.lastCourseDate)
        return (
          <span key={`time-${row.userId}`}>
            {row.lastCourseDate !== null
              ? moment(row.lastCourseDate).locale("es").format("DD/MM/YYYY")
              : "-"}
          </span>
        )
      },
      csvFormatter: (column, row) =>
        moment(row.lastCourseDate).format("D/M/YYYY"),
    },
  ]

  columns.push({
    dataField: "actions",
    text: "Acciones",
    sort: false,
    csvExport: false,
    formatter: dropDownActions,
    formatExtraData: {
      active: dropDown,
    },
  })

  const pageOptions = {
    sizePerPage: sizePerPage === undefined ? 10 : sizePerPage,
    totalSize: filteredUsers.length,
    custom: true,
    showTotal: true,
    page: page === undefined ? 1 : page,
    onPageChange: page => {
      setPage(page)
    },
    onSizePerPageChange: sizePerPage => {
      setSizePerPage(sizePerPage)
    },
  }

  /*const getXlsData = users => {
    let data = users.map((user, index) => {
      return {
        ID: user.userId,
        "Nombre completo": `${user.firstName} ${user.lastName}`,
        Correo: user.email,
        Cliente: user.nombreCliente === null ? "-" : user.clientName,
        Grupo: user.groupId === null ? "Ninguno" : user.groupName,
        "Último ingreso":
          user.aceptotyc !== null && user.aceptoPrivacidadDatos !== null
            ? moment(user.lastActivity).format("D/M/YYYY HH:mm [hrs.]")
            : "-",
        Perfil: `N${user.profileId}`,
        Estatus: user.status ? "Activo" : "Inactivo",
      }
    })

    return {
      Usuarios: data,
    }
  }*/

  const defaultSorted = [{ dataField: "firstName", order: "asc" }]

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const afterFilter = (newResult, newFilters) => {}

  return loading && users === undefined ? (
    <div className="mt-3 mb-3">
      <div className="spinner-chase">
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
      </div>
    </div>
  ) : (
    <Fragment>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="userId"
        columns={columns}
        data={users}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="userId"
            columns={columns}
            data={users}
            search={{
              afterSearch: newData => {
                setFilteredUsers(newData)
              },
            }}
          >
            {toolkitProps => (
              <React.Fragment>
                <Row className="mb-2 pt-3">
                  <Col lg={2} md={3} sm={4} xs={4}></Col>
                  <Col
                    lg={8}
                    md={6}
                    sm={4}
                    xs={4}
                    className="text-center"
                  ></Col>
                  <Col
                    lg={{ size: 2 }}
                    md={{ size: 3 }}
                    sm={{ size: 4 }}
                    xs={4}
                  >
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar
                          {...toolkitProps.searchProps}
                          onSearch={value => {
                            setPage(1)
                            toolkitProps.searchProps.onSearch(value)
                          }}
                          placeholder="Buscar"
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                </Row>

                {users !== undefined && filteredUsers !== undefined && (
                  <Row>
                    <Col xs={8}>
                      <p>
                        {filteredUsers.length === users.length ? (
                          <span>
                            Mostrando{" "}
                            <strong>{numberWithCommas(users.length)}</strong>{" "}
                            usuarios
                          </span>
                        ) : (
                          <span>
                            Mostrando{" "}
                            <strong>
                              {numberWithCommas(filteredUsers.length)}
                            </strong>{" "}
                            usuarios de un total de{" "}
                            <strong>{numberWithCommas(users.length)}</strong>
                          </span>
                        )}
                      </p>
                    </Col>
                    <Col xs={4} className="d-flex justify-content-end pr-4">
                      {/*<XlsExporter
                        data={getXlsData(filteredUsers)}
                        dataName={"Usuarios"}
                        name="Usuarios"
                        label="Exportar lista"
                      />*/}
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <Fragment>
                        <BootstrapTable
                          keyField={"userId"}
                          bordered={false}
                          striped={true}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                        {users?.length <= 0 && (
                          <Row>
                            <Col md={12} className="text-center">
                              -- No hay registros --
                            </Col>
                          </Row>
                        )}
                      </Fragment>
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </Fragment>
  )
}

SoftskillsAbilitiesUsersListTable.propTypes = {
  users: PropTypes.array,
}

export default SoftskillsAbilitiesUsersListTable
