import React, { Component, Fragment, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardBody, Badge } from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb2"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { useDispatch, useSelector } from "react-redux"
import {
  answerSoftskillsSurvey,
  answerSoftskillsSurveyClean,
  finishSoftskillsUserCourse,
  finishSoftskillsUserCourseClean,
  getSoftskillsCourse,
  getSoftskillsUserCourses,
  startSoftskillsUserCourse,
  startSoftskillsUserCourseClean,
} from "store/actions"
import UploadCourseImageModal from "components/SoftSkills/CourseUsers/UploadCourseImageModal"
import UploadCourseTextModal from "components/SoftSkills/CourseUsers/UploadCourseTextModal"
import SweetAlert from "react-bootstrap-sweetalert"
import moment from "moment"
import {
  AvField,
  AvForm,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation"
import { getSoftskillsSurvey } from "store/softskills_users/get_survey/actions"
import { Link } from "react-router-dom"

const SoftskillsUserCourseDetail = props => {
  const { courseId } = useParams()

  const [showUploadImage, setShowUploadImage] = useState(false)
  const [showUploadText, setShowUploadText] = useState(false)
  const [showFinishAlert, setShowFinishAlert] = useState(false)
  const [showSurveyAnswered, setShowSurveyAnswered] = useState(false)
  const [currentAbility, setCurrentAbility] = useState(false)
  const [formError, setFormError] = useState(false)
  const [answer1, setAnswer1] = useState(0)
  const [answer2, setAnswer2] = useState(0)
  const [answer3, setAnswer3] = useState(0)
  const [answer4, setAnswer4] = useState(0)
  const [answer5, setAnswer5] = useState(0)
  const [answer6, setAnswer6] = useState(0)

  const dispatch = useDispatch()

  const {
    user,
    course,
    courses,
    loading,
    courseUser,
    courseUserLoading,
    finishLoading,
    finishDone,
    survey,
    loadingSurveyAnswer,
    doneSurveyAnswer,
  } = useSelector(store => ({
    user: store.Profile.user,
    courses: store.SoftskillsUserCoursesList.courses,
    course: store.SoftskillCourseGet.course,
    loading: store.SoftskillCourseGet.loading,
    courseUser: store.SoftskillsUserCourseStart.course,
    courseUserLoading: store.SoftskillsUserCourseStart.loading,
    finishLoading: store.SoftskillsUserCourseFinish.loading,
    finishDone: store.SoftskillsUserCourseFinish.done,
    survey: store.SoftskillsSurveyGet.survey,
    loadingSurveyAnswer: store.SoftskillsSurveyAnswer.loading,
    doneSurveyAnswer: store.SoftskillsSurveyAnswer.done,
  }))

  const userId = user?.idUsuario ?? 0

  const started = courseUser !== undefined
  const courseStart =
    courseUser !== undefined ? courseUser.createdAt : undefined
  const finished = courseUser !== undefined ? courseUser.statusId === 2 : false
  const approved = courseUser !== undefined ? courseUser.statusId === 3 : false
  const courseEnd = courseUser !== undefined ? courseUser.finishedAt : undefined

  useEffect(() => {
    if (userId !== 0) {
      dispatch(getSoftskillsCourse(courseId))
      dispatch(startSoftskillsUserCourse({ courseId, userId }))
      dispatch(getSoftskillsSurvey({ courseId, userId }))
      return () => {
        dispatch(startSoftskillsUserCourseClean())
      }
    }
  }, [courseId, userId])

  useEffect(() => {
    if (courses.length === 0) {
      dispatch(getSoftskillsUserCourses(userId))
    }
  }, [userId])

  useEffect(() => {
    courses.forEach(ability => {
      if (ability.current === true) {
        const exists = ability.courses.some(
          c => c.courseId === parseInt(courseId)
        )
        if (exists && !currentAbility) {
          setCurrentAbility(true)
        }
      }
    })
  }, [courses])

  const getModality = modalityId => {
    if (modalityId == 1) return "Online Course"
    if (modalityId == 2) return "Material"
    if (modalityId == 3) return "Playlist"
    if (modalityId == 4) return "Presencial"
    if (modalityId == 5) return "External content"
  }

  const getLanguage = languageId => {
    if (languageId == 1) return "Español"
    if (languageId == 2) return "Inglés"
    if (languageId == 3) return "Portugués"
    if (languageId == 4) return "Alemán"
  }

  const handleFinishCourse = () => {
    if (course?.imageUpload === true) {
      setShowUploadImage(true)
    }
    if (course?.openText === true) {
      setShowUploadText(true)
    }
  }

  const finishCourseWithImage = image => {
    dispatch(
      finishSoftskillsUserCourse({
        courseId: parseInt(courseId),
        userId,
        image,
      })
    )
  }

  const finishCourseWithText = text => {
    dispatch(
      finishSoftskillsUserCourse({ courseId: parseInt(courseId), userId, text })
    )
  }

  const getQuestionForm = (number, question, value, onChange) => {
    return (
      <Fragment>
        <p className="mt-3">
          {number}. {question}
        </p>
        <AvRadioGroup
          name={`answer${number}`}
          className="form-control"
          onChange={event => onChange(parseInt(event.target.value))}
          value={value}
          validate={{
            required: {
              value: true,
              errorMessage: "Este campo es requerido",
            },
          }}
        >
          <Row className="mb-2 mt-2">
            <Col md={1}>
              <AvRadio value={1} label="1" />
            </Col>
            <Col md={1}>
              <AvRadio value={2} label="2" />
            </Col>
            <Col md={1}>
              <AvRadio value={3} label="3" />
            </Col>
            <Col md={1}>
              <AvRadio value={4} label="4" />
            </Col>
            <Col md={1}>
              <AvRadio value={5} label="5" />
            </Col>
            <Col md={7}>
              * Siendo 1 la calificación más baja y 5 la más alta
            </Col>
          </Row>
        </AvRadioGroup>
        <div className="pb-2"></div>
      </Fragment>
    )
  }

  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(true)
      return
    }
    setFormError(false)

    const request = {
      courseId: courseId,
      userId: userId,
      answers: [
        {
          questionId: 1,
          answer: answer1.toString(),
        },
        {
          questionId: 2,
          answer: answer2.toString(),
        },
        {
          questionId: 3,
          answer: answer3.toString(),
        },
        {
          questionId: 4,
          answer: answer4.toString(),
        },
        {
          questionId: 5,
          answer: answer5.toString(),
        },
        {
          questionId: 6,
          answer: answer6.toString(),
        },
      ],
    }

    dispatch(answerSoftskillsSurvey(request))
  }

  if (finishDone) {
    dispatch(finishSoftskillsUserCourseClean())
    dispatch(getSoftskillsCourse(courseId))
    dispatch(getSoftskillsUserCourses(userId))
    dispatch(startSoftskillsUserCourse({ courseId, userId }))
    setShowFinishAlert(true)
  }

  if (doneSurveyAnswer) {
    dispatch(answerSoftskillsSurveyClean())
    dispatch(getSoftskillsSurvey({ courseId, userId }))
    setShowSurveyAnswered(true)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Detalle de curso {course?.courseName ?? ""} | Soft Skills
          </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={4}>
              <h4>Detalle del curso</h4>
            </Col>
            <Col sm={8}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "dashboard", title: "Inicio", link: "/soft-skills" },
                  {
                    key: "my-courses",
                    title: "Mis cursos",
                    link: "/soft-skills/my-courses",
                  },
                  { key: "my-course", title: course?.courseName ?? "Curso" },
                ]}
              />
            </Col>
          </Row>
          {course !== undefined && courseUser !== undefined && (
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row className="mb-4">
                      <Col className="d-flex justify-content-end">
                        <Link
                          to={"/soft-skills/my-courses"}
                          className="btn btn-link"
                        >
                          <i
                            className="fa fa-times"
                            style={{ fontSize: "24px" }}
                          ></i>
                        </Link>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <img src={course.image} className="img-fluid" />
                      </Col>
                      <Col md={9}>
                        <Row>
                          <Col md={9}>
                            <h4 className="text-secondary">
                              {course.ability?.abilityName ?? ""}
                            </h4>
                            <h2 className="text-primary">
                              {course.courseName ?? ""}
                            </h2>
                            <hr />
                            <p>
                              <strong>Modalidad:</strong>{" "}
                              {getModality(course.modalityId)}
                            </p>
                            <p>
                              <strong>Idioma:</strong>{" "}
                              {getLanguage(course.languageId)}
                            </p>
                            <p>
                              <strong>
                                Días límite para finalizar el curso:
                              </strong>{" "}
                              {course.limitDays}
                            </p>
                            <p>
                              <strong>Duración estimada del curso:</strong>{" "}
                              {course.duration} minutos
                            </p>
                            <p>
                              <strong>Tipo de evidencia:</strong>{" "}
                              {course?.imageUpload === true
                                ? "Archivo"
                                : course?.openText === true
                                ? "Texto"
                                : ""}
                            </p>
                          </Col>
                          <Col md={3}>
                            {approved ? (
                              <h3>
                                <span
                                  className="badge bg-pill bg-success pt-2 pl-2 pr-2"
                                  style={{ display: "block" }}
                                >
                                  Completado
                                </span>
                              </h3>
                            ) : finished ? (
                              <h3>
                                <span
                                  className="badge bg-pill bg-warning pt-2 pl-2 pr-2"
                                  style={{ display: "block" }}
                                >
                                  En validación
                                </span>
                              </h3>
                            ) : (
                              <h3>
                                <span
                                  className="badge bg-pill bg-secondary pt-2 pl-2 pr-2"
                                  style={{ display: "block" }}
                                >
                                  Pendiente de completar
                                </span>
                              </h3>
                            )}
                            {!currentAbility && (
                              <div className="alert alert-warning">
                                No puedes cargar evidencia a este curso hasta
                                completar primero los cursos de la habilidad
                                actual y las antecesoras a la habilidad de este
                                curso.
                              </div>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={9}>
                            <p>
                              <strong>Objetivo del curso</strong>
                            </p>
                            <p>{course.description}</p>
                            <p className="mt-2">
                              <strong>Link para ingresar:</strong>{" "}
                              <a
                                href={course.link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {course.link}
                              </a>
                            </p>
                          </Col>
                          <Col md={3}>
                            {finished || !currentAbility ? (
                              <></>
                            ) : (
                              <button
                                onClick={handleFinishCourse}
                                type="button"
                                className="btn btn-primary btn-block btn-xl"
                              >
                                <i className="fa fa-check"></i> Completar curso
                              </button>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {courseUser.validationComments !== null &&
                      courseUser.statusId !== 2 && (
                        <Fragment>
                          <hr />
                          <Row>
                            <Col md={12}>
                              <h2>Valoración</h2>
                              <h3>
                                Tipo de evidencia:{" "}
                                {course?.imageUpload === true
                                  ? "Archivo"
                                  : course?.openText === true
                                  ? "Texto"
                                  : ""}
                              </h3>
                              {course?.imageUpload === true && (
                                <Fragment>
                                  <p>
                                    Dar clic en el archivo para poder visualizar
                                    o descargarlo y validarlo
                                  </p>
                                  <a
                                    className="btn btn-primary"
                                    href={courseUser.image}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <i className="fa fa-download"></i> Ver o
                                    descargar archivo
                                  </a>
                                </Fragment>
                              )}
                              {course?.openText === true && (
                                <Fragment>
                                  <p>
                                    Revise los comentarios ingresados por el
                                    usuario
                                  </p>
                                  <small>
                                    Fecha de evidencia:{" "}
                                    {moment(courseEnd)
                                      .locale("es")
                                      .format("DD/MM/YYYY HH:mm [hrs.]")}
                                  </small>
                                  <div
                                    className="p-3 alert-light"
                                    style={{
                                      border: "1px solid #cccccc",
                                    }}
                                  >
                                    {courseUser.text}
                                  </div>
                                </Fragment>
                              )}
                              <h4 className="mt-4">
                                Comentarios de la revisión
                              </h4>
                              <p>{courseUser.validationComments}</p>
                            </Col>
                          </Row>
                        </Fragment>
                      )}
                    {(courseUser.statusId === 2 || courseUser.statusId === 3) &&
                      survey !== undefined && (
                        <Fragment>
                          <hr />
                          <Row>
                            <Col md={12}>
                              <h2>Encuesta de satisfacción</h2>
                              {survey.length > 0 ? (
                                <div className="alert alert-success">
                                  <h3 className="text-center pt-2">
                                    <i className="fa fa-check"></i> Recibimos
                                    sus respuestas exitosamente.
                                  </h3>
                                </div>
                              ) : (
                                <AvForm
                                  className="form-horizontal"
                                  onSubmit={handleSubmit}
                                >
                                  {formError && (
                                    <div className="alert alert-danger">
                                      Existen errores en el formulario.
                                    </div>
                                  )}
                                  {getQuestionForm(
                                    1,
                                    "¿Qué tan útil consideras el tema del curso?",
                                    answer1,
                                    value => setAnswer1(value)
                                  )}
                                  {getQuestionForm(
                                    2,
                                    "¿El nivel del curso se ha ajustado a sus necesidades?",
                                    answer2,
                                    value => setAnswer2(value)
                                  )}
                                  {getQuestionForm(
                                    3,
                                    "¿La explicación de los temas te pareció correcta?",
                                    answer3,
                                    value => setAnswer3(value)
                                  )}
                                  {getQuestionForm(
                                    4,
                                    "¿La duración te pareció adecuada a la explicación del contenido?",
                                    answer4,
                                    value => setAnswer4(value)
                                  )}
                                  {getQuestionForm(
                                    5,
                                    "¿En general, cómo calificarías este curso?",
                                    answer5,
                                    value => setAnswer5(value)
                                  )}
                                  {getQuestionForm(
                                    6,
                                    "¿La plataforma digital de habilidades que usas para seguimiento de cursos te parece fácil y adecuada? ",
                                    answer6,
                                    value => setAnswer6(value)
                                  )}
                                  <Row className="mb-3 mt-3">
                                    <Col
                                      xl={9}
                                      lg={9}
                                      md={9}
                                      sm={9}
                                      xs={0}
                                    ></Col>
                                    <Col xl={3} lg={3} md={3} sm={3} xs={6}>
                                      <button
                                        className={`btn btn-secondary float-right  btn-block`}
                                        type="submit"
                                      >
                                        Guardar encuesta
                                      </button>
                                    </Col>
                                  </Row>
                                </AvForm>
                              )}
                            </Col>
                          </Row>
                        </Fragment>
                      )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      {showUploadImage && (
        <UploadCourseImageModal
          show={true}
          onCancel={() => setShowUploadImage(false)}
          onConfirm={image => {
            setShowUploadImage(false)
            finishCourseWithImage(image)
          }}
        />
      )}
      {showUploadText && (
        <UploadCourseTextModal
          show={true}
          onCancel={() => setShowUploadImage(false)}
          onConfirm={text => {
            setShowUploadText(false)
            finishCourseWithText(text)
          }}
        />
      )}
      {showFinishAlert && (
        <SweetAlert
          title={`Curso completado`}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setShowFinishAlert(false)
          }}
        >
          Su evidencia ha sido recibida y enviada a validación.
        </SweetAlert>
      )}
      {showSurveyAnswered && (
        <SweetAlert
          title={`Encuesta respondida`}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setShowSurveyAnswered(false)
          }}
        >
          Su encuesta ha sido recibida.
        </SweetAlert>
      )}
    </React.Fragment>
  )
}

export default SoftskillsUserCourseDetail
