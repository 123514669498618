import React, { useState, useEffect, Fragment } from "react"
import ReactSpeedometer from "react-d3-speedometer"
import { PropTypes } from "prop-types"

const GuageAbilities = props => {
  if (props.value !== undefined && props.total !== undefined) {
    const avg = (props.value / props.total) * 100

    return (
      <Fragment>
        <div className="speedometer d-flex justify-content-center">
          <div>
            <p className="text-center">Progreso</p>
            <ReactSpeedometer
              width={240}
              height={150}
              className="ReactSpeedometer"
              maxSegmentLabels={5}
              segments={1000}
              value={avg}
              ringWidth={20}
              minValue={0}
              maxValue={100}
              startColor="#ed1c2e"
              endColor="#34c38f"
              needleColor="#005596"
              currentValueText={""}
            />
          </div>
        </div>
        <style jsx="true">{`
          .ReactSpeedometer {
            width: 50%;
          }
          .speedometer {
            overflow: auto;
          }
          .speedometer h4 {
            width: 240px;
          }
        `}</style>
      </Fragment>
    )
  }
  return <div></div>
}

GuageAbilities.propTypes = {
  value: PropTypes.number,
  total: PropTypes.number,
}

export default GuageAbilities
