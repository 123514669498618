import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import { connect, useDispatch, useSelector } from "react-redux"
import { meNew } from "store/actions"

const NavbarSoftskills = props => {
  const [dashboard, setdashboard] = useState(false)
  const [courses, setcourses] = useState(false)
  const [groups, setgroups] = useState(false)
  const [utility, setutility] = useState(false)
  const [analytics, setanalytics] = useState(false)
  const [configuration, setconfiguration] = useState(false)

  const dispatch = useDispatch()

  const { whoIAm, whoIAmNew } = useSelector(state => ({
    whoIAm: state.Profile.user,
    whoIAmNew: state.ProfileNew.user,
  }))

  const idRol = whoIAm?.idRol ?? 0
  const idRolSS = whoIAmNew?.softskillsProfileId ?? 0
  const email = whoIAm?.email ?? ""
  const isAdmin = idRolSS === 1

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul !== null ? ul.getElementsByTagName("a") : []
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [])

  useEffect(() => {
    dispatch(meNew())
  }, [])

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <div className="topnav menu-celf-background">
        <div className="container-fluid">
          {idRol !== 0 && (
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={props.leftMenu}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {
                        setdashboard(!dashboard)
                      }}
                      to="/start"
                    >
                      {props.t("La Academia Fester")}
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to={"/soft-skills"}
                    >
                      Habilidades Blandas
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to={"/soft-skills/my-courses"}
                    >
                      Mis cursos
                    </Link>
                  </li>
                  {isAdmin && (
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        onClick={e => {}}
                        to={"/soft-skills/reports"}
                      >
                        Reportes
                      </Link>
                    </li>
                  )}
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {}}
                      to={"/soft-skills/notifications"}
                    >
                      Notificaciones
                    </Link>
                  </li>
                  {isAdmin && (
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle arrow-none"
                        onClick={e => {
                          setconfiguration(!configuration)
                        }}
                        to="#"
                      >
                        Configuración <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: configuration,
                        })}
                      >
                        <Link
                          to="/soft-skills/adm-courses"
                          className="dropdown-item"
                        >
                          Cursos
                        </Link>
                        <Link
                          to="/soft-skills/adm-abilities"
                          className="dropdown-item"
                        >
                          Habilidades
                        </Link>
                        <Link
                          to="/soft-skills/adm-users"
                          className="dropdown-item"
                        >
                          Usuarios
                        </Link>
                      </div>
                    </li>
                  )}
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      onClick={e => {}}
                      to={"#"}
                    >
                      Ayuda
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </nav>
          )}
        </div>
      </div>
      <style jsx="true">{`
        .nav-social {
          position: absolute;
          right: 0;
        }
        .nav-social i {
          font-size: 20px !important;
        }
        .nav-social-1 {
          right: 0 !important;
        }
        .nav-social-2 {
          right: 36px !important;
        }
        .nav-social-3 {
          right: 72px !important;
        }
      `}</style>
    </React.Fragment>
  )
}

NavbarSoftskills.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(NavbarSoftskills))
)
